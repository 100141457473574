import layoutHeader from '@/header';
const meta = { auth: true};

export default [
    {
		path: '/login',
		name: 'login',
		meta: {
			title: '登录页',
		},
		component: () => import('@/views/login/login.vue'),
		beforeEnter: (to, from, next) => {
			document.title = '登录页';
			next();
		}
	},
    {
		path: '/',
		name: '_home',
		redirect: { name: 'home' },
		component: layoutHeader,
		children:[
			{
				path: '/home',
				name: 'home',
				meta:{
					title:'总览首页',
					...meta
				},
				component: () => import('@/views/home/home.vue'),
				beforeEnter: (to, from, next) => {
					document.title = '总览首页';
					next();
				}
			},
			{
				path: '/userCenter',
				name: 'userCenter',
				meta:{
					title:'个人中心',
					...meta
				},
				component: () => import('@/views/home/userCenter.vue'),
			},
			{
				path: '/editPassword',
				name: 'editPassword',
				meta:{
					title:'修改密码',
					...meta
				},
				component: () => import('@/views/home/editPassword.vue'),
			},
			{
				path: '/platformSet',
				name: 'platformSet',
				meta:{
					title:'平台配置',
					...meta
				},
				component: () => import('@/views/home/platformSet.vue'),
			},
		]
    },
	{
		path:'/camera',
		name:'camera',
		meta:{
			title:'摄像头',
			...meta
		},
		component: () => import('@/views/device/camera.vue'),
	},
	{
		path:'/alarmData',
		name:'alarmData',
		meta:{
			title:'告警数据',
			...meta
		},
		component: () => import('@/views/alarm/alarmData.vue'),
	}
]