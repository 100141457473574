import Vue from 'vue';
import Router from 'vue-router';
import routes from './routers';

const RouterPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return RouterPush.call(this, location).catch(err => err)
}
const RouterReplace = Router.prototype.replace
Router.prototype.replace = function replace (location) {
  return RouterReplace.call(this, location).catch(err => err)
}
Vue.use(Router);
import cookies from "@/libs/util.cookies"
import {logout} from "@/libs/tool.js";
const router = new Router({
  routes
})

/**
 * 路由拦截
 * 权限验证
 */
 router.beforeEach(async (to, from, next) => {
  let token = cookies.get('token');
  // 验证当前路由所有的匹配中是否需要有登录验证的
  if (to.matched.some(r => r.meta.auth)) {
    if (token && token !== 'undefined') {
      let teamInfo = JSON.parse(localStorage.getItem('teamInfo'));
      if(!teamInfo){
        logout();
      }
      next();
    } else {
      // 没有登录的时候跳转到登录界面
      next({
        name: 'login',
      })
    }
  }else{
    // 不需要校验
    next();
  }
  /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
  if(to.fullPath == "/login"){
    if(token){
      next({
        name: 'home',
      });
    }else {
      next();
    }
  }
})

router.afterEach(to => {
  // 更改标题
  window.document.title = to.meta.title;
})
export default router