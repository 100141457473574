import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import {getUserInfo} from "@/api/data.js";

export default new Vuex.Store({
  state: {
    userinfo:'',
    platformData:'',
  },
  mutations: {
    setPlatformData(state,data){
      state.platformData = data || JSON.parse(localStorage.getItem('platformData'));
      localStorage.setItem('platformData',JSON.stringify(state.platformData));
    },
    getUserInfo(state){
      getUserInfo().then(res => {
        if(res.code === 1){
          let user = res.data.userinfo;
          state.userinfo = {platform_id:user.platform_id,uid:user.uid,username:user.username,
          account:user.account,type:user.type,avatar:user.img_url,sex:user.sex};
        }
      })
    },
  },
  actions: {
  },
  modules: {
  }
})
