<template>
  <div class="d2-layout-header-aside-group">
    <!-- 主体内容 -->
    <div class="d2-layout-header-aside-content" flex="dir:top">
      <!-- 顶栏 -->
      <div class="d2-theme-header">
        <div class="header-left">
          <div class="logo-group">
            <img :src="platformData ? platformData.logo_url : require('@/assets/images/_logo.png')" class="img-logo"/>
            <div class="logo-name">{{platformData ? platformData.platform_name.length > 12 ? platformData.platform_name.substring(0,12) + '...' : platformData.platform_name : ''}}</div>
          </div>
          <div class="home-box">
            <div class="content-box cancel" v-if="$route.path === '/home'">
              <img src="@/assets/images/home2.png"/>
              <div class="home-txt">返回首页</div>
            </div>
            <div class="content-box" @click="$router.push('/')" v-else>
              <img src="@/assets/images/home1.png"/>
              <div class="home-txt">返回首页</div>
            </div>
          </div>
        </div>
        <!-- 顶栏右侧 -->
        <div class="header-right">
          <div class="button-box">
            <div class="content-box" @click="goAiYun('')">
              <div class="fullscreen-txt">配置工作台</div>
              <img src="@/assets/images/Vector.png"/>
            </div>
            <div class="content-box" @click="goModelYun('')">
              <div class="fullscreen-txt">流程处理工作台</div>
              <img src="@/assets/images/Vector.png"/>
            </div>
            <div class="content-box" @click="changeFull" v-if="!isFullscreen">
              <div class="fullscreen-txt">全屏切换</div>
              <img src="@/assets/images/Fullscreen.png"/>
            </div>
            <div class="content-box cancel" @click="changeFull" v-else>
              <div class="fullscreen-txt">取消全屏</div>
              <img src="@/assets/images/Frame.png"/>
            </div>
          </div>
          <div class="avatar-box">
            <!--  :trigger="['click']" -->
            <a-dropdown size="small" class="d2-mr" style="display:flex;align-items:center">
              <div @click="e => e.preventDefault()" class="img-box" :style="{'background-image':`url(${userInfo ? userInfo.avatar : ''})`}"></div>
              <a-menu slot="overlay">
                <a-menu-item class="dropdown-box">
                  <div class="dropdown-user padding">{{userInfo ? userInfo.username : ''}}</div>
                  <div class="dropdown-team padding">
                    <div class="dropdown-team-name overflow">{{platformData ? platformData.platform_name : ''}}</div>
                    <div class="dropdown-team-tag">我的团队</div>
                  </div>
                  <div class="line"></div>
                  <div class="dropdown-item dropdown-info">
                    <div class="dropdown-router padding" @click="$router.push('/userCenter')">
                      <a-icon :component="svgList.userSvg" style="font-size:18px;"/>
                      <span class="txt">个人中心</span>
                    </div>
                    <div class="dropdown-router padding" @click="$router.push('/editPassword')">
                      <a-icon :component="svgList.setPass" style="font-size:18px;"/>
                      <span class="txt">修改密码</span>
                    </div>
                    <div class="dropdown-router padding" @click="$router.push('/platformSet')">
                      <a-icon :component="svgList.platformSet" style="font-size:18px;"/>
                      <span class="txt">平台配置</span>
                    </div>
                  </div>
                  <div class="line padding"></div>
                  <div class="dropdown-logout" @click="logOff">
                    <a-icon type="logout" />
                    <span class="txt">退出登录</span>
                  </div>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </div>
      <div class="d2-layout-content">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import {logout} from "@/libs/tool.js";
import screenfull from 'screenfull';
import {getSiteUrl} from "@/api/data.js";
import cookies from "@/libs/util.cookies.js";
import {svgList} from '@/assets/svg/svg.js';
export default {
  data () {
    return {
      isFullscreen:false,
      matchList:[],
      appList:[],
      teamInfo:'',
      svgList:svgList,
    }
  },
  computed: {
    userInfo(){
      return this.$store.state.userinfo;
    },
    platformData(){
      return this.$store.state.platformData;
    }
  },
  methods: {
    init(){
      if(!screenfull.isEnabled){ //判断一下浏览器是否支持全屏显示
        this.$message.warning('浏览器不能全屏');
        return false;
      };
      this.listen();
      document.addEventListener('keydown', this.keydown);  
    },
    listen(){
      if (screenfull.isEnabled) {
        screenfull.on('change', () => {
          this.isFullscreen = screenfull.isFullscreen;
        })
      }
    },
    keydown(e){
      if (e.keyCode === 122) {
        e.returnValue = false;
        this.changeFull();
      }
    },
    changeFull(){
      this.isFullscreen = !this.isFullscreen
      screenfull.toggle();
    },
    logOff(){
      logout();
    },
    goModelYun(){
      getSiteUrl({
        team_id:this.teamInfo.team_id,
        matching_userid:this.teamInfo.matching_userid,
      }).then(res => {
        if(res.code === 1){
          window.open(`${res.data.path}`, '_blank');
        }
      })
    },
    goAiYun(){
      let token = cookies.get('token');
      if(this.userInfo){
        let urlData = `uid=${this.userInfo.uid}&token=${token}&platform_name=${this.platformData.platform_name}&platform_id=${this.userInfo.platform_id}&loginStatu=${this.userInfo.loginStatu}&type=${this.userInfo.type}`;
        if(process.env.NODE_ENV === 'production'){
          window.open(`https://cloud.ai-safer.cn/#/home?${urlData}`, '_blank');
        }else{
          window.open(`http://ai.skyinfordata.com:61008/#/home?${urlData}`, '_blank');
        }
      }
    }
  },
  mounted(){
    this.teamInfo = JSON.parse(localStorage.getItem('teamInfo'));
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.d2-layout-header-aside-content{
  height:100%;
  min-width: 1440px;
  min-height: 800px;
  display: flex;
  flex-direction: column;
}
.d2-layout-header-aside-group{
  height:100%;
  width:100%;
  overflow: hidden;
  position: relative;
  font-family: PingFang SC;
  display: flex;
  flex-direction: column;
  .d2-theme-header{
    display: flex;
    justify-content: space-between;
    height: 64px;
    border-bottom: 1px dashed #E3E5E6;
    padding-right:16px;
    box-sizing: border-box;
    .header-left, .header-right{
      display:flex;
      align-items: center;
    }
    .button-box{
      display:flex;
    }
    .logo-group {
      text-align: center;
      display: flex;
      align-items: center;
      transition: width 0.3s;
      min-width: 208px;    
      max-width: 380px;
      cursor: pointer;
      .img-logo{
        width:40px;
        height: 40px;
        border-radius: 10px;
        margin:0 16px;
      }
      .logo-name{
        font-family: Source Han Sans CN;
        font-size: 22px;
        color: #5E6366;
      }
    }
    .home-box{
      margin-left:14px;
      .content-box{
        >img{
          margin-left:0;
        }
        .home-txt{
          margin-left: 10px;
        }
      }
      .cancel{
        .home-txt{
          color:#367BF5;
        }
      }
    }
    .content-box{
      display:flex;
      align-items: center;
      justify-content: space-between;
      background: #F7F9FA;
      border-radius: 10px;
      height:40px;
      padding:0 10px;
      box-sizing: border-box;
      cursor: pointer;
      margin-left:16px;
      >img{
        margin-left:10px;
      }
      .home-txt{
        color: #5E6366;
        font-size: 12px;
      }
      .fullscreen-txt{
        color: #5E6366;
        font-size: 12px;
      }
    }
    .cancel{
      .fullscreen-txt{
        color:#367BF5;
      }
    }
    .avatar-box{
      margin-left:16px;
      .img-box{
        width:40px;
        height:40px;
        border-radius: 50%;
        background-size: cover;
      }
    }
  }
  .d2-layout-content{
    flex:1;
    overflow: hidden;
  }
}
.btn-text {
  padding: 0 12px;
  border-radius: 4px;
  cursor: pointer;
}
.ant-dropdown-menu{
  width:238px;
  border-radius: 10px;
  font-family: Source Han Sans CN;
}
.ant-dropdown-menu{
  padding:0;
}
.dropdown-box{
  padding:20px 0 0;
  box-sizing: border-box;
  cursor: pointer;
  .padding{
    padding:0 16px;
  }
  .line{
    height:1px;
    width:100%;
    margin:0 16px;
    box-sizing: border-box;
    background: #F3F3F3;
  }
  .dropdown-user{
    font-size: 18px;
    line-height: 27px;
    height: 27px;
    color:#404040;
  }
  .dropdown-team{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
    height: 24px;
    margin-bottom:15px;
    .dropdown-team-name{
      font-size:14px;
      color:#A2A2A2;    
    }
    .dropdown-team-tag{
      font-size: 12px;
      background: #1890FF;
      border-radius: 41px;
      padding:0 12px;
      color: #fff;
    }
  }
  .dropdown-item{
    padding-bottom:12px;
    .dropdown-router{
      margin-top:10px;
      display:flex;
      align-items: center;
      font-size: 14px;
      line-height: 28px;
      height: 28px;
      color:#404040;
      .txt{
        margin-left:12px;
      }
    }
  }
  .dropdown-info{
    .dropdown-router:hover{
      background: rgba(24, 144, 255, 0.1);  
       color:#1890FF;
    }
    .dropdown-router:active{
      background: #1890FF;  
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      color:#fff
    }
  }
  .dropdown-logout{
    font-size:14px;
    line-height: 53px;
    text-align: center;
    .txt{
      margin-left: 15px;
    }
  }
  .dropdown-logout:hover,.dropdown-logout.active{
    background: #FF5454;
    box-shadow: 0px -1px 4px rgba(149, 0, 0, 0.2);
    border-radius: 0px 0px 10px 10px;
    color:#fff
  }
}
.dropdown-box:hover{
  background: transparent !important;
}
</style>
