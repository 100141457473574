import { message } from 'ant-design-vue';
import cookies from "./util.cookies";
import router from '@/router';
/**
 * @description 创建一个错误
 * @param {String} msg 错误信息
 */
export function errorCreate (error) {
  // 显示提示
  message.error({
    content: error,
    duration: 3
  })
}

/**退出登录 */
export const logout = () => {
  // 删除cookie
  cookies.remove('token');
  let accountInfo = JSON.parse(localStorage.getItem('account-Info'));
  localStorage.clear();
  localStorage.setItem("account-Info", JSON.stringify(accountInfo));
  // 跳转路由
  router.push({ name: 'login' })
}

/**
 * 生成参数
 * @param bizParameter 业务参数
 * @param pageObj 分页对象（可为空）
 */
 export const generateParameter = (bizParameter) => {
  let parameter = JSON.parse(JSON.stringify(bizParameter));
  delNullOrEmpty(parameter);
  return parameter;
};

/**
 * 删除对象中的属性存在null或者空的（
 * @param object 对象
 * @returns {boolean}
 */
export const delNullOrEmpty = (object) => {
  if (object) {
    for (var key in object) {
      if (!object[key] && object[key] !== 0) {
        delete object[key];
      }
    }
  }
};