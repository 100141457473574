import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
import { Icon } from "ant-design-vue";
Vue.use(Icon);
import fullscreen from 'vue-fullscreen'
Vue.use(fullscreen)
import './index.scss';

Vue.config.productionTip = false;

import cookies from "@/libs/util.cookies"
let vm = new Vue({
  router,
  store,
  render: h => h(App),
  mounted(){
    let token = cookies.get('token');
    if (token && token !== 'undefined') {
      this.$store.commit('getUserInfo');
      this.$store.commit('setPlatformData');
    }
  }
}).$mount('#app')

// 将APP组件上得方法添加到vue得原型上，这样我们就可以在每个子组件上使用APP组件内的方法
// 完成删除确认弹框得调用
Vue.prototype.$confirmDel = vm.$children[0].confirmDel;

// 全局指令  按钮权限
// Vue.directive('permissions', {
//   inserted: (el, binding) => {
//     let permissionList = JSON.parse(localStorage.getItem('btnPermissions'));
//     if (!permissionList.includes(binding.value)) {
//       el.parentNode.removeChild(el)
//     }
//   }
// })

/* 处理表格中的空值 */
Vue.prototype.$getData = (data) => {
  data.map(item => {
    for (var key in item) {
      if (item[key] === null || item[key] === '' || item[key] === 'null') {
        item[key] = '--';
      }
    }
  })
  return data
};
// 判断当前浏览器是否为IE浏览器
Vue.prototype.$isIE = () => {
  return !!videojs.browser.IE_VERSION;
}
/* 获取列表的序号 */
Vue.prototype.$getIndex = (index, page, pageSize, total) => {
  let num = (page - 1) * pageSize + (index + 1);
  if (total >= num) {
    return num
  }
};
/* 图片转换为base64 */
Vue.prototype.$getBase64Image = (image) => {
  let canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  let context = canvas.getContext('2d');  
  context.fillStyle = "#fff";
  context.fillRect(0, 0, image.width, image.height);
  context.drawImage(image, 0, 0, image.width, image.height);
  let quality = 0.8;
  // 这里的dataurl就是base64类型
  let dataURL = canvas.toDataURL('image/jpeg', quality);
  return dataURL;
};
