import axios from 'axios';
import {errorCreate,logout} from "@/libs/tool";
import cookies from "@/libs/util.cookies"

let baseURL = process.env.VUE_APP_API;
let count = 0;
const service = axios.create()
service.interceptors.response.use(function (response) {
  if(response.data.code !== 1 && response.data.code!==500 && response.data.code!==-1){
    errorCreate(response.data.msg);
  }
  if(response.data.code===500 || response.data.code===-1){ // 登陆过期
    if(count == 0){
      errorCreate(response.data.msg);
    }
    logout();
    count ++ ;
  }
  return response.data
}, function(err) {
  errorCreate("请求出错!");
  return Promise.reject(err);
})
let cameraAjax = axios.create();
export {cameraAjax};

export const request = {
  header:{
    'Content-Type': "application/json;charset=utf-8",
  },
  get(url,params={}){
    this.header.token = cookies.get('token');
    return service({
      method: 'get',
      url: baseURL+url,
      headers: this.header,
      params: params
    })
  },
  post(url,params){
    this.header.token = cookies.get('token');
    return service({
      method: 'post',
      url: baseURL+url,
      headers: this.header,
      data: params,
    })
  }
}
