<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view/>
      <!-- 删除确认框，这个方法会被定义在vue原型上给每个子组件使用 -->
      <a-modal :visible.sync="showDelConfirm" width="420px" class="deleteDialog centerDialog">
        <div class="dialog-icon">
          <img src="@/assets/images/delicon.png" />
        </div>
        <div class="del-confirm-content">是否删除选择的数据</div>
        <span slot="footer" class="dialog-footer">
          <a-button @click="showDelConfirm = false">取 消</a-button>
          <a-button type="primary" class="confirm-button" @click="andleOk" :disabled="isDisable">确 定</a-button>
        </span>
      </a-modal>
    </div>
  </a-config-provider>
</template>
<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  data() {
    return {
      zh_CN,
      showDelConfirm:false,
      confirmCallback: null,
      isDisable: false,
    };
  },
  methods:{
    confirmDel(callback, options = {}) { // 该方法会被放在vue得原型上
      this.isDisable = false;
      this.showDelConfirm = true;
      this.confirmCallback = callback;
    },
    andleOk() {
      this.isDisable = true;
      this.confirmCallback();
      this.showDelConfirm = false
    },
  }
}
</script>
<style lang="scss">
.deleteDialog{
  .ant-modal-body{
    text-align: center;
    padding:30px 24px 14px;
    .del-confirm-content{
      font-size: 18px;
      line-height: 48px;
      color:rgba(0, 0, 0, 0.85);
    }
  }
  .ant-modal-footer{
    border-top:0;
    text-align: center;
    padding: 0 16px 16px;
    .confirm-button{
      margin-left:40px;
    }
  }
}
</style>
