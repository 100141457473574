import {request} from "./index.js";

// 登录
export const login = (params) => {
    return request.post("webapi/user/login",params)
}
// 发送短信接口
export const sendSms = (params) => {
    return request.post("webapi/user/send_sms",params)
}
// 短信登录接口
export const smsLogin = (params) => {
    return request.post("webapi/user/sms_login",params)
}
// 重置密码 
export const resetPass = (params) => {
    return request.post("webapi/user/reset_pass",params)
}
// 获取视搭应用列表
export const getMatchList = (params) => {
    return request.post("webapi/home/match_list",params)
}
//获取视搭待办、抄送、发起任务接口 
export const getDash = (params) => {
    return request.post("webapi/home/get_dash",params)
}
// 获取跳转视搭配具体页面
export const getSiteUrl = (params) => {
    return request.post("webapi/home/get_site_url",params)
}
// 获取自定义配置应用
export const getAppList = () => {
    return request.get("webapi/home/app_list")
}
// 获取ai云数据统计 
export const getIndexData = (params) => {
    return request.get("api/index/index_data",params)
}
// 获取ai云最新告警
export const getLatestNewData = (params) => {
    return request.get("api/index/latest_new_data",params)
}
// 获取ai云7天告警数据
export const getWeekAlarmData = () => {
    return request.get("api/index/week_alarm_data")
}
// 用户信息 
export const getUserInfo = () => {
    return request.get("api/user/get_user_info")
}
// 修改用户信息 
export const setUser = (params) => {
    return request.post("api/user/user_center_edit",params)
}
// 修改密码 
export const editUserPassword = (params) => {
    return request.post("api/user/user_edit_password",params)
}
// 修改平台配置 
export const editPlatformInfo = (params) => {
    return request.post("webapi/platform/edit_info",params)
}
// 获取平台配置
export const getPlatformInfo = (params) => {
    return request.post("webapi/platform/get_details",params)
}
// 摄像头列表
export const getCameraList = (params) => {
    return request.get("api/device/cameraList",params)
}
// 设备层级 
export const deviceGroup = () => {
    return request.get("api/device/cameraGroupList")
}
// 设备详情
export const deviceDetail = (params) => {
    return request.get("api/device/deviceCameraDetail",params)
}
// 用户列表
export const userList = (params) => {
    return request.get("api/user/userList",params)
}
// 告警类型
export const alarmType = (params) => {
    return request.get("api/alarm/AlarmTypeList",params)
}
// 所有告警
export const allAlarmList = (params) => {
    return request.get("api/alarm/alarmCameraListAll",params)
}
// 告警详情 
export const alarmRecordDetail = (params) => {
    return request.get("api/alarm/alarmRecordDetail",params)
}
// 修改状态 
export const setAlarmRecordStatu = (params) => {
    return request.post("api/alarm/set_alarm_record_status",params)
}